<template>
  <div class="header--logo">
    <!-- <div class="logo-text" v-if="!notShowLogo"></div>
    <el-divider direction="vertical" v-if="!notShowLogo"></el-divider> -->
    <div class="website--title">{{$t('d.IIoT')}}</div>
  </div>
</template>

<script>
export default {
  name:'logo',
  computed: {
    notShowLogo() {
      return  process.env.VUE_APP_NOT_SHOW_LOGO
    }
  }

}
</script>

<style scoped lang="scss">
.header--logo{
  height: 100%;
  padding:0 16px;
  display:flex;
  align-items: center;
  font-size:20px;
  font-weight: 500;
  color: #FFFFFF;
  .logo-text{
    width:120px;
    height:32px;
    background-image:url('../../../assets/images/logo-white-text.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
  }
  .el-divider{
    background-color:#4f95da;
  }
}
</style>
