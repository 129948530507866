<template>
  <div v-if="info" class="user-info">
    <div class="user-avatar" @click="handleClick">
      <img v-if="info.avater" :src="info.avatar">
      <i v-else class="el-icon-user"></i>
    </div>
    <div class="user-name">{{ info.name }}</div>
  </div>
</template>

<script>
export default {
  name:'user-info',
  props:{
    info:Object
  },
  methods:{
    async handleClick(){
      // console.log('logout ------')
      await this.$store.dispatch('logout')
      this.$router.push('/' + this.$i18n.locale + '/login')
    }
  }
}
</script>

<style scoped lang="scss">
.user-info{
  display:flex;
  align-items:center;
  padding:0 24px;
  .user-avatar{
    height:40px;
    width:40px;
    border-radius: 30px;
    overflow:hidden;
    img{
      height:100%;
      width:100%;
    }
    i{
      display: flex;
      width:40px;
      height:40px;
      color:#1096DB;
      font-weight: bold;
      font-size:24px;
      align-items: center;
      justify-content: center;
      background:#fff;
    }
  }
  .user-name{
    margin-left:16px;
    color:#fff;
  }
}
</style>