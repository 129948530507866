<!--
  基础容器层 用于登陆之后的页面容器 包含 菜单栏 ， 导航栏
-->

<template>
  <div class="base-container">
    <div class="c--header">
      <div class="h--left">
        <logo :logo="websiteConfig.headerLogo"></logo>
      </div>
      <div class="h--right">
        <!-- 语言切换 -->
        <div class="h--languages">
          <el-select
            v-model="$i18n.locale"
            popper-class="user-login--language-select"
            @change="changeLan"
          >
            <el-option value="zh-cn" label="简体中文"></el-option>
            <el-option value="en" label="English"></el-option>
          </el-select>
        </div>
        <user-info :info="userInfo"></user-info>
        <!-- <el-divider direction="vertical"></el-divider>
        <header-menu></header-menu> -->
      </div>
    </div>
    <div class="c--body">
      <div class="b--menu">
        <els-menu
          :column="menus"
          :defaultActive="currentPath"
          @select="handleSelect"
        ></els-menu>
      </div>
      <div class="b--main">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import elsMenu from "@/components/els-menu/main.vue";
import logo from "./components/logo";
import userInfo from "./components/userinfo";
import headerMenu from "./components/menu";
import { mapGetters } from "vuex";
//
import { websitConfig } from "@/config/website";
import { HomePath } from "@/config/menus.js";
import { saveLocal, getLocal } from "@/utils/util.js";
import { getMenu } from "@/api/base/login";
import { getButtons, getMenus } from "@/utils/menuTree";
import { buildMenus } from "@/config/menus.js";
import { parseNodes } from "@/views/components/rulesDesign/utils";
export default {
  components: { elsMenu, logo, userInfo, headerMenu },
  data: () => ({
    currentPath: "/hanyun-iot/home",
  }),
  computed: {
    ...mapGetters(["menus", "userInfo"]),
    // elMenu(){
    //   return {
    //     backgroundColor:"#545c64",
    //     textColor:"#fff",
    //     activeTextColor:"#ffd04b"
    //   }
    // },
    websiteConfig() {
      return { ...websitConfig };
    },
  },
  methods: {
    handleSelect(p) {
      if (this.$route.path == p) return;
      this.$router.push(p);
    },
    async changeLan() {
      saveLocal("i18nLocal", this.$i18n.locale);
      const val = this.$store.state.ruleDesign.nodes;
      if (val) {
        const { ruleList } = parseNodes(val);
        this.$store.commit("ruleDesign/setNodes", val);
        saveLocal("ruleDesign.ruleList", ruleList);
      }
      const { result: tree } = await getMenu();
      const buttons = getButtons(tree);
      const sourceMenus = getMenus(tree);
      const menus = buildMenus(sourceMenus);
      this.$store.commit("setButtons", buttons);
      this.$store.commit("setSourceMenus", sourceMenus);
      this.$store.commit("setMenus", menus);
      const str = this.$route.fullPath;
      const s = str.indexOf("/", str.indexOf("/") + 1);
      const r = "/" + this.$i18n.locale + str.slice(s);
      this.$router.push(r);
      $location.reload();
    },
  },
  mounted() {
    this.currentPath = this.$route.path;
  },
};
</script>

<style lang="scss">
.base-container {
  width: 100vw;
  height: 100vh;
  // overflow:auto;
  overflow: hidden;
  .c--header {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #081b33; // 暂定
    .h--right {
      display: flex;
      align-items: center;
      .h--languages {
        width: 140px;
      }
    }
  }
  .c--body {
    height: calc(100vh - 64px);
    display: flex;
    .b--menu {
      min-width: 208px;
      height: 100%;
      padding-top: 6px;
      box-sizing: border-box;
      background: #081b33; // 暂定
      .els-menu {
        .el-menu {
          background: #081b33;
          border-color: #081b33;
        }
        .el-menu-item {
          height: 40px;
          line-height: 40px;
          color: #ddd;
          &:hover {
            background: #fff;
            color: #1890ff;
            i {
              color: #1890ff;
            }
          }
          &.is-active {
            background: #1890ff;
            border-right: 2px solid #1890ff;
            color: #fff;
            i {
              color: #fff;
            }
          }
        }
      }
    }
    .b--main {
      // padding:24px;
      // box-sizing:border-box;
      background-color: #f0f2f5;
      flex: 1;
      height: 100%;
      overflow: auto;
      >div{
        padding:16px;
        width:100%;
        box-sizing: border-box;
        height:100%;
        >div{
          margin:0;
          padding:0;
        }
        ._bread{
          height:40px;
        }
        ._tip{
          height:40px;
          padding-bottom:16px;
          box-sizing: content-box;
        }
        ._header{
          height:32px;
          padding-bottom:8px;
          box-sizing: content-box;
        }
        ._content{
          height:calc(100% - 40px - 40px);
          box-sizing: border-box;
          &.has-tip{
            height:calc(100% - 40px - 56px - 40px);
          }
        }
      }
    }
  }
}
</style>
