<template>
  <div class="els-menu">
    <el-menu v-bind="elMenu" :default-active="currentActive" @select="handleSelect" @open="handleOpen" @close="handleClose">
      <template v-for="(elem,ins) in column" >
        <els-elem :key="elem.id || elem.name || ins" :elem="elem" :slots="$scopedSlots"></els-elem>
      </template>
    </el-menu>
  </div>
</template>

<script>
import elsElem from '../els-elem/main'
import {mapState} from "vuex"
export default {
  name:'els-menu',
  components:{elsElem},
  props:{
    options:{type:Object,default:() => ({})},
    column:Array,
    defaultActive:String,
  },
  data:() => ({
    currentActive:'',
  }),
  computed:{
    ...(mapState(["sourceMenus"])),
    elMenu(){
      const o = this.$attrs.elMenu || this.options.elMenu || {}
      const d = {}
      //
      return {...d,...o}
    },
  },
  methods:{
    handleSelect(index,indexPath){
      // this.setBread(index)
      //
      // if(this.auto){
      //   this.$route && this.$route.push(index)
      // }
      //
      this.$emit('select',index,indexPath)
    },
    handleOpen(index,indexPath){
      this.$emit('open',index,indexPath)
    },
    handleClose(index,indexPath){
      this.$emit('close',index,indexPath)
    },
    setBread(path){
     const pathArr = [];
     const arr = this.$router.getRoutes();
     const p = arr.filter(item =>{
      return item.path === path;
     })
    //  console.log('======>',p)
     if(p && p.length > 0){
       pathArr.push({
         name:p[0].meta.title,
        //  path:p[0].index
       })
       this.$store.commit('setBreadMenus',p.map(e => ({name:e.meta.title})))
     }else{

     }
    }
  },
  watch:{
    "$route":function(v){
      // console.log('.....',v)
      const path = v.matched?.reverse()?.[0].path;
      this.currentActive = path;
      this.setBread(path)
    }
  },
  created(){
    this.currentActive = this.defaultActive
  },
  mounted(){
    // const route = this.$route;
    // const path = route.matched?.pop()?.path;
    // this.setBread(path)
  }
}
</script>


<style lang="scss" scoped>
.els-menu{
  height:100%;
  overflow: auto;
}
</style>